<template>
    <div class="vehicle-view-modal">
        <LoadingScreen v-if="is_loading_vehicle"/>
        <ModalContainer v-else :title="vehicle.attributes.license_plate" identifier="vehicle-view-modal" :closable="true">
            <div class="vehicle-info-container">
                <div class="misc-info">
                    <div class="info-container">
                        <p class="title">{{$t('vehicles.brand')}}</p>
                        <p class="information">{{vehicle.relationships.brand.data.attributes.name}}</p>
                    </div>
                    <div class="info-container">
                        <p class="title">{{$t('vehicles.model')}}</p>
                        <p class="information">{{vehicle.attributes.model}}</p>
                    </div>
                    <div class="info-container">
                        <p class="title">{{$t('vehicles.transmission')}}</p>
                        <p class="information">{{vehicle.attributes.transmission}}</p>
                    </div>
                </div>
                <div class="images-info" v-if="vehicleImages">
                    <carousel :perPage="1" :mouseDrag="true" :navigationEnabled="true" paginationColor="#C4C4C4" paginationActiveColor="#FFC829">
                        <slide v-for="(image,index) in vehicleImages" :key="index">
                            <img class="carousel-image" :src="image.preview"/>
                        </slide>
                    </carousel>
                </div>
            </div>

            <div class="buttons-container">
                <Button type="submit" className="--outline --secondary --small" :onclick="() => close(false)">
                    {{ $t('cancel') }}
                </Button>
                <Button type="submit" className="--primary --small" :onclick="() => close(true)">
                    {{ $t('bookings.book') }}
                </Button>
            </div>
        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputSelect from "../form/FormInputSelect";
import Button from "../Button";
import FormInputPassword from "@/components/form/FormInputPassword.vue";
import FormInputDateTime from "../form/FormInputDateTime";
import LoadingScreen from "../LoadingScreen";
import { Carousel, Slide } from 'vue-carousel';

export default {
    name: "VehicleViewModal",
    components: {
        LoadingScreen, FormInputPassword, Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer, FormInputDateTime, Carousel, Slide},
    data() {
        return {
            vehicle: null,
            vehicleImages: null,
            is_loading_vehicle: true,
        }
    },

    props: {
        vehicle_id: {
            type: Number,
            required: false,
            default: null,
        }
    },

    methods: {
        close(status) {
            this.$modal.hide('vehicle-view-modal', status);
        },

        async retrieveVehicle() {
            this.is_loading_vehicle = true;
            await this.$axios.get(`vehicles/${this.vehicle_id}`)
                .then(({data}) => {
                    this.vehicle = data.data;
                    this.is_loading_vehicle = false;
                })
                .catch(e => {
                    this.is_loading_vehicle = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('vehicle.error_retrieve')),
                        type: 'error',
                    });
                });
        },

        async retrieveVehicleImages() {
            this.is_loading_vehicle_images = true;
            await this.$axios.get(`vehicles/${this.vehicle_id}/documents`)
                .then(({data}) => {
                    if (data.data.length) {
                        this.vehicleImages = data.data.map(d => ({
                            id: d.id,
                            name: d.attributes.name,
                            preview: d.attributes.image_path,
                            file: null,
                        }));
                    }

                    this.is_loading_vehicle_images = false;
                })
                .catch(e => {
                    this.is_loading_vehicle_images = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('vehicles.error_documents_retrieve')),
                        type: 'error',
                    });
                });
        },
        async retrieveBrands() {
            this.is_loading_brands = true;

            await this.$axios.get('brands/list')
                .then(({data}) => {
                    this.brands = data.data;
                    this.is_loading_brands = false;
                })
                .catch(e => {
                    this.is_loading_brands = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('brands.error_retrieve')),
                        type: 'error',
                    });
                });
        },
    },
    async mounted(){
        await this.retrieveVehicle();
        await this.retrieveVehicleImages();
        await this.retrieveBrands();
    }
}
</script>

<style lang="scss" scoped>
.vehicle-view-modal {

    .info-container {
        @apply flex flex-col mb-4;

        .title {
            @apply font-bold mb-2;
        }

        .information {
            @apply text-sm;
        }

    }

    .carousel-image {
        @apply rounded-lg;

        object-fit: fill;
        aspect-ratio: 16 / 9;
        max-height: 24rem;
        width: 100%;
    }

    .room-info-container{
        @apply mt-2;

        .misc-info {
            @apply flex flex-row;

            .info-container {
                @apply w-1/2;
            }

        }
    }

    .amenities-info {
        @apply mb-8;
    }

    .buttons-container {
        @apply flex flex-row justify-between mt-4;
    }
}
</style>
