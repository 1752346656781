<template>
    <div class="bookings-update-modal">
        <ModalContainer :title="$t('bookings.update_booking')" identifier="bookings-update-modal" :closable="true">
            <Headbar>
                <template v-slot:left>
                    <h1 v-if="original">{{ original.attributes.reference }}</h1>
                </template>
                <template v-slot:right>
                    <Button className="--secondary --outline --mini --big-text"
                            v-if="original && original.attributes.status === 'pending'"
                            :onclick="()=>toggleStart()">
                        <font-awesome-icon :icon="['fal', 'play']"/>
                    </Button>
                    <Button className="--secondary --outline --mini --big-text"
                            v-if="original && original.attributes.status === 'in_progress'"
                            :onclick="()=>toggleStop()">
                        <font-awesome-icon :icon="['fal', 'stop']"/>
                    </Button>
                    <Button v-if="$store.getters.hasPermission('update users')" className="--primary --small --wider"
                            :class="{spinner: is_saving}" :onclick="save">
                        {{ $t('save') }}
                    </Button>
                </template>
            </Headbar>
            <main>
                <div class="section-container forms-container">
                    <Form class="form" :disabled="!$store.getters.hasPermission('update bookings') || is_saving">
                        <SectionHeader :title="$t('bookings.booking_details')"></SectionHeader>
                        <div class="form-body">
                            <FormGroupTwo>
                                <FormInputDateTime v-model="$v.booking.from.$model" identifier="pick_up_date"
                                                   :label="$t('bookings.pick_up_date')"
                                                   :placeholder="$t('bookings.pick_up_date')"
                                                   :disabled="is_saving || !$store.getters.hasPermission('update bookings')"
                                                   :minute-interval="15"
                                                   :has-error="$v.booking.from.$error || is_time_equal || is_date_different">
                                    <template v-slot:errors>
                                        <p v-if="!$v.booking.from.required">
                                            {{ $t('validation.x_is_required', {x: $t('bookings.pick_up_date')}) }}
                                        </p>
                                        <p v-else-if="is_time_equal">
                                            {{ $t('bookings.pick_up_time_cant_be_same') }}
                                        </p>
                                        <p v-else-if="is_date_different">
                                            {{ $t('bookings.pick_up_date_cant_be_different') }}
                                        </p>
                                    </template>
                                </FormInputDateTime>
                                <FormInputDateTime v-model="$v.booking.to.$model" identifier="drop_off_date"
                                                   :label="$t('bookings.drop_off_date')"
                                                   :placeholder="$t('bookings.drop_off_date')"
                                                   :disabled="is_saving || !$store.getters.hasPermission('update bookings')"
                                                   :minute-interval="15"
                                                   :has-error="$v.booking.to.$error || is_drop_off_before"
                                                   :min-date="minReturnDate"
                                                   @input="changedToDate">
                                    <template v-slot:errors>
                                        <p v-if="!$v.booking.to.required">
                                            {{ $t('validation.x_is_required', {x: $t('bookings.drop_off_date')}) }}
                                        </p>
                                        <p v-else-if="is_drop_off_before">
                                            {{ $t('bookings.error_drop_off_before') }}
                                        </p>
                                    </template>
                                </FormInputDateTime>
                            </FormGroupTwo>
                            <FormGroupTwo>
                                <FormInputText v-model="$v.booking.pick_up_location.$model"
                                               identifier="pick_up_location"
                                               :label="$t('bookings.pick_up_location')"
                                               :placeholder="$t('bookings.pick_up_location')"
                                               :disabled="is_saving || !$store.getters.hasPermission('update bookings')"
                                               :has-error="$v.booking.pick_up_location.$error">
                                    <template v-slot:errors>
                                        <p v-if="!$v.booking.pick_up_location.required">
                                            {{ $t('validation.x_are_required', {x: $t('bookings.pick_up_location')}) }}
                                        </p>
                                    </template>
                                </FormInputText>
                                <FormInputText v-model="$v.booking.drop_off_location.$model"
                                               identifier="drop_off_location"
                                               :label="$t('bookings.drop_off_location')"
                                               :placeholder="$t('bookings.drop_off_location')"
                                               :disabled="is_saving || !$store.getters.hasPermission('update bookings')"
                                               :has-error="$v.booking.drop_off_location.$error">
                                    <template v-slot:errors>
                                        <p v-if="!$v.booking.drop_off_location.required">
                                            {{ $t('validation.x_are_required', {x: $t('bookings.drop_off_location')}) }}
                                        </p>
                                    </template>
                                </FormInputText>
                            </FormGroupTwo>
                            <FormGroupTwo>
                                <FormInputSelect v-model="$v.booking.license_plate.$model" identifier="license_plate"
                                                 :label="$t('bookings.license_plate')" :options="vehicleOptions"
                                                 :placeholder="$t('bookings.license_plate')"
                                                 :disabled="is_saving || !$store.getters.hasPermission('update bookings')"
                                                 :has-error="$v.booking.license_plate.$error" track-by="id"
                                                 :display-custom-label="(row) => `${row.attributes.license_plate} - ${row.relationships.brand.data.attributes.name} - ${row.attributes.model}`">
                                    <template v-slot:errors>
                                        <p v-if="!$v.booking.drop_off_location.required">
                                            {{ $t('validation.x_are_required', {x: $t('bookings.license_plate')}) }}
                                        </p>
                                    </template>
                                </FormInputSelect>
                                <FormInputText v-model="$v.booking.booking_by.$model" identifier="booking_by"
                                               :label="$t('bookings.booking_by')"
                                               :placeholder="$t('bookings.booking_by')" :disabled="true"
                                               :has-error="$v.booking.booking_by.$error">
                                    <template v-slot:errors>
                                        <p v-if="!$v.booking.booking_by.required">
                                            {{ $t('validation.x_are_required', {x: $t('bookings.booking_by')}) }}
                                        </p>
                                    </template>
                                </FormInputText>
                            </FormGroupTwo>
                            <FormGroupTwo>
                                <FormInputText v-model="$v.booking.obo.$model" identifier="obo"
                                               :label="$t('bookings.obo')"
                                               :placeholder="$t('bookings.obo')"
                                               :disabled="is_saving || !$store.getters.hasPermission('update bookings')"
                                               :has-error="$v.booking.obo.$error">
                                    <template v-slot:errors>
                                        <p v-if="!$v.booking.obo.required">
                                            {{ $t('validation.x_are_required', {x: $t('bookings.obo')}) }}
                                        </p>
                                    </template>
                                </FormInputText>
                            </FormGroupTwo>
                        </div>
                    </Form>
                    <Form class="form" :disabled="!$store.getters.hasPermission('update bookings') || is_saving">
                        <SectionHeader :title="$t('bookings.trip_details')"></SectionHeader>
                        <div class="form-body">
                            <FormGroupTwo>
                                <FormInputText v-model="booking.trip_start_km" identifier="car_km_before"
                                               :has-error="is_start_higher_than_finish"
                                               :label="$t('bookings.trip_start_km')"
                                               :placeholder="$t('bookings.trip_start_km')"
                                               :disabled="!$store.getters.hasPermission('update bookings')">
                                    <template v-slot:errors>
                                        <p v-if="is_start_higher_than_finish">
                                            {{ $t('bookings.trip_start_cant_be_higher_than_finish') }}
                                        </p>
                                    </template>
                                </FormInputText>
                                <FormInputText v-model="booking.trip_finish_km" identifier="car_km_after"
                                               :label="$t('bookings.trip_finish_km')"
                                               :placeholder="$t('bookings.trip_finish_km')"
                                               :disabled="!$store.getters.hasPermission('update bookings')">
                                </FormInputText>
                                <FormInputText v-model="parseInt(booking.trip_total_km).toLocaleString('en')"
                                               identifier="trip_km"
                                               :label="$t('bookings.trip_km')" :placeholder="$t('bookings.trip_km')"
                                               :disabled="true">
                                </FormInputText>
                            </FormGroupTwo>
                        </div>
                    </Form>
                </div>
            </main>
        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputSelect from "../form/FormInputSelect";
import {required, email} from 'vuelidate/lib/validators'
import Button from "../Button";
import FormInputPassword from "@/components/form/FormInputPassword";
import FormInputDateTime from "../form/FormInputDateTime";
import _ from "lodash";
import StartBookingModal from "@/components/modal/StartBookingModal.vue";
import StopBookingModal from "@/components/modal/StopBookingModal.vue";
import SectionHeader from "@/components/SectionHeader.vue";
import Headbar from "@/components/headbar/Headbar.vue";

export default {
    name: "BookingsUpdateModal",
    components: {
        Headbar,
        SectionHeader,
        FormInputPassword,
        Button,
        FormInputSelect,
        FormGroupTwo,
        FormInputText,
        Form,
        ModalContainer,
        FormInputDateTime
    },
    data() {
        return {
            original: null,
            booking: {
                from: null,
                to: null,
                pick_up_location: null,
                drop_off_location: null,
                license_plate: null,
                booking_by: null,
                obo: null,
                trip_start_km: null,
                trip_finish_km: null,
                trip_total_km: null,
            },
            serverParams: {sorting: []},
            vehicleOptions: [],
            is_loading: false,
            is_saving: false,
            is_time_equal: false,
            is_date_different: false,
            is_initial_to_change: false,
            is_drop_off_before: false,
            is_start_higher_than_finish: false,
        }
    },
    props: {
        booking_id: {
            type: Number,
            required: true
        }
    },
    validations: {
        booking: {
            from: {
                required
            },
            to: {
                required
            },
            pick_up_location: {
                required
            },
            drop_off_location: {
                required
            },
            license_plate: {
                required
            },
            booking_by: {
                required
            },
            obo: {},

            trip_start_km: {},

            trip_finish_km: {},

            trip_total_km: {},
        }
    },
    methods: {
        close(status) {
            this.$modal.hide('bookings-update-modal', status);
        },
        toggleStart() {
            this.$modal.show(
                StartBookingModal, {
                    title: `${this.$t('bookings.start_booking')} - ${this.original.attributes.reference}`,
                    message: this.$t('bookings.please_enter_kilometers'),
                    confirmText: this.$t('start'),
                    cancelText: this.$t('cancel'),
                    confirmClass: '--primary',
                    cancelClass: '--secondary --outline',
                    booking: this.original
                },
                {
                    name: 'start-booking-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true) {
                            this.retrieveOriginalBooking();
                        }
                    }
                }
            );
        },
        toggleStop() {
            this.$modal.show(
                StopBookingModal, {
                    title: `${this.$t('bookings.stop_booking')} - ${this.original.attributes.reference}`,
                    message: this.$t('bookings.please_enter_kilometers'),
                    confirmText: this.$t('stop'),
                    cancelText: this.$t('cancel'),
                    confirmClass: '--primary',
                    cancelClass: '--secondary --outline',
                    booking: this.original
                },
                {
                    name: 'stop-booking-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true) {
                            this.retrieveOriginalBooking();
                        }
                    }
                }
            );
        },
        async populate() {
            if (!this.original) return;

            this.$v.booking.from.$model = this.original.attributes.from;
            this.booking.to = this.original.attributes.to;
            this.$v.booking.pick_up_location.$model = this.original.attributes.pick_up_location;
            this.$v.booking.drop_off_location.$model = this.original.attributes.drop_off_location;
            this.$v.booking.obo.$model = this.original.attributes.obo;
            this.$v.booking.license_plate.$model = _.find(this.vehicleOptions, {id: this.original.relationships.vehicle.data.id});
            this.$v.booking.booking_by.$model = this.original.relationships.user.data.attributes.name;
            this.booking.trip_start_km = this.original.attributes.trip_start_km;
            this.booking.trip_finish_km = this.original.attributes.trip_finish_km;
            this.booking.trip_total_km = this.booking.trip_finish_km - this.booking.trip_start_km;
        },

        changedToDate() {
            this.is_initial_to_change = true;
        },

        async retrieveVehiclesOptions() {
            this.is_loading_vehicles = false;
            await this.$axios.get('vehicles/list')
                .then(({data}) => {
                    this.vehicleOptions = data.data;
                    this.is_loading_vehicles = false;
                })
                .catch(e => {
                    this.is_loading_vehicles = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('vehicles.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        async retrieveOriginalBooking() {
            this.is_loading = true

            await this.$axios.get(`bookings/${this.booking_id}`)
                .then(({data}) => {
                    this.original = data.data;
                    this.populate();
                    this.is_loading = false
                })
                .catch(e => {
                    this.is_loading = false

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('bookings.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.retrieveRoles();
        },

        save() {
            this.is_time_equal = false;
            this.is_date_different = false;
            this.is_drop_off_before = false;
            this.is_start_higher_than_finish = false;


            this.$v.booking.$touch();
            if (this.$v.booking.$anyError || this.is_saving)
                return;

            let payload = Object.assign({}, this.$v.booking.$model);

            if (this.$moment(payload.to).isSameOrBefore(this.$moment(payload.from))) {
                this.is_drop_off_before = true;
                this.is_saving = false;
                return;
            }

            if (payload.from === payload.to) {
                this.is_time_equal = true;
                this.is_saving = false;
                return;
            }

            if (payload.from.substring(0, 10) !== payload.to.substring(0, 10)) {
                this.is_date_different = true;
                this.is_saving = false;
                return;
            }

            if (parseInt(payload.trip_start_km) > parseInt(payload.trip_finish_km)) {
                console.log("TRIGGERED")
                this.is_start_higher_than_finish = true;
                return;
            }

            payload.vehicle_id = payload.license_plate.id
            payload.user_id = payload.booking_by.id

            delete payload.license_plate;
            delete payload.booking_by;

            if (this.original.attributes.drop_off_location === payload.drop_off_location)
                delete payload.drop_off_location

            if (this.original.attributes.from === payload.from)
                delete payload.from

            if (this.original.attributes.obo === payload.obo)
                delete payload.obo

            if (this.original.attributes.pick_up_location === payload.pick_up_location)
                delete payload.pick_up_location

            if (this.original.attributes.to === payload.to)
                delete payload.to

            if (this.original.attributes.trip_finish_km === payload.trip_finish_km)
                delete payload.trip_finish_km

            if (this.original.attributes.trip_start_km === payload.trip_start_km)
                delete payload.trip_start_km

            if (this.original.attributes.trip_total_km === payload.trip_total_km)
                delete payload.trip_total_km

            this.is_saving = true;

            this.$axios.patch(`bookings/${this.booking_id}`, payload).then(({data}) => {
                this.$notify({
                    text: this.$t('bookings.success_updated'),
                    type: 'success',
                });

                this.is_saving = false;
                this.close(true);
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('bookings.error_update')),
                    type: 'error',
                });
            });
        },
    },
    computed: {
        todayDate() {
            let date = new Date();
            return this.$moment(date).format('YYYY-MM-DD HH:mm');
        },
        minReturnDate() {
            return this.$moment(this.booking.from).add(1, 'hours').format('YYYY-MM-DD');
        },
        maxReturnDate() {
            return this.$moment(this.booking.from).endOf('day').format('YYYY-MM-DD HH:mm');
        }
    },

    async mounted() {
        await this.retrieveVehiclesOptions();
        await this.retrieveOriginalBooking();
    },
}
</script>

<style lang="scss" scoped>
.status-select-container {
    @apply flex flex-row items-center ml-auto -mr-3;

    p {
        @apply mr-4 text-xs;
    }

    .input-group {
        @apply mb-0 w-40 mr-4;
    }
}
</style>
