export default {
    nav: {
        home: 'Home',
        login: 'Login',
        calendar: 'Calendar',
        reset_password: 'Reset Password',
        bookings: 'Bookings',
        issues: 'Issues',
        vehicles: 'Vehicles',
        reports: 'Reports',
        roles_permissions: 'Roles & Permissions',
        users: 'Users',
        profile: 'Profile',
        logout: 'Logout',
    },
    home: {
        title: 'Arkafort Car Booking System',
        subtitle: 'Check out our fleet and book a car according to your needs',
        pick_up_date: 'Pick-up date & time',
        return_date: 'Return date & time',
        pick_up_required: 'Pick-up date & time are required',
        return_required: 'Return date & time are required'
    },
    bookings: {
        id: 'ID',
        date_time_from: 'Date & Time From',
        date_time_to: 'Date & Time To',
        license_plate: 'License Plate',
        model: 'Model',
        booking_by: 'Booking by',
        add_booking: 'Add Booking',
        search_bookings: 'Search bookings',
        booking_details: 'Booking Details',
        trip_details: 'Trip Details',
        car_km_before: 'Car Kilometers before',
        car_km_after: 'Car Kilometers after',
        trip_km: 'Trip kilometers',
        book: 'Book',
        audits: 'Audits',
        user: 'User',
        event: 'Event',
        detail: 'Detail',
        new_values: 'New Values',
        old_values: 'Old Values',
        vehicle_id: 'Vehicle ID',
        status: 'Status',
        from: 'Pick-Up Time',
        to: 'Drop-Off Time',
        user_id:'User ID',
        reference: 'Reference',
        date_and_time: 'Date & Time',
        obo: 'O.B.O',
        booking: 'Booking',
        bookings: 'Bookings',
        vehicles: 'Vehicles',
        vehicle: 'Vehicle',
        start_booking: 'Start Booking',
        stop_booking: 'Stop Booking',
        kilometers: 'Kilometers',
        mileage_must_be_greater_than: 'Mileage to stop booking must be greater than {x} KM',
        please_enter_kilometers: 'Please enter the car\'s kilometers value below',
        brand_id: 'Brand ID',
        pick_up_date: 'Pick-Up Date & Time',
        drop_off_date: 'Drop-Off Date & Time',
        pick_up_date_only: 'Pick-Up Date',
        drop_off_date_only: 'Drop-Off Date',
        pick_up_location: 'Pick-Up Location',
        drop_off_location: 'Drop-Off Location',
        pick_up_time_cant_be_same: 'Pick-up time and drop-off time can\'t be the same hour',
        pick_up_date_cant_be_different: 'Pick-up date and drop-off date must be within the same day',
        view_booking: 'View Booking',
        update_booking: 'Update Booking',
        delete_booking: 'Delete Booking',
        prompt_delete: 'Are you sure you want to delete this booking?',
        error_delete: 'Failed to delete booking',
        error_retrieve: 'Failed to retrieve bookings',
        error_create: 'Failed to create booking',
        error_update: 'Failed to update booking',
        error_drop_off_before: 'Drop-off date & time cannot be less than or equal to pick-up date & time',
        success_delete: 'Booking has been deleted',
        success_created: 'Booking has been created',
        success_updated: 'Booking has been updated',
        error_retrieve_audits: 'Failed to retrieve audits',
        slot_not_available: 'Time slot is not available',
        trip_start_km: 'Trip Start (km)',
        trip_finish_km: 'Trip Finish (km)',
        trip_start_cant_be_higher_than_finish: 'Trip start km can\'t be higher than trip finish km'
    },
    brands: {
        brand: 'Brand',
        brands: 'Brands',
        view_brand: 'View Vehicle Brand',
        update_brand: 'Update Vehicle Brand',
        delete_brand: 'Delete Vehicle Brand',
        prompt_delete: 'Are you sure you want to delete this vehicle brand?',
        error_delete: 'Failed to delete vehicle brand',
        error_retrieve: 'Failed to retrieve vehicle brands',
        error_create: 'Failed to create vehicle brand',
        error_update: 'Failed to update vehicle brand',
        success_delete: 'Vehicle brand has been deleted',
        success_created: 'Vehicle brand has been created',
        success_updated: 'Vehicle brand has been updated',
    },
    issues: {
        id: 'ID',
        license_plate: 'License Plate',
        reported_by: 'Reported by',
        status: 'Status',
        report_an_issue: 'Report an Issue',
        search_issues: 'Search issues',
        issue: 'Issue',
        issues: 'Issues',
        message: 'Message',
        comment: 'Comment',
        view_issue: 'View Issue',
        update_issue: 'Update Issue',
        delete_issue: 'Delete Issue',
        prompt_delete: 'Are you sure you want to delete this issue?',
        error_delete: 'Failed to delete issue',
        error_retrieve: 'Failed to retrieve issues',
        error_create: 'Failed to create issue',
        error_update: 'Failed to update issue',
        success_delete: 'Issue has been deleted',
        success_created: 'Issue has been created',
        success_updated: 'Issue has been updated',
        success_updated_vehicle: 'Vehicle status has been updated',
        error_updated_vehicle: 'Failed to update vehicle status',

    },
    vehicles: {
        name: 'Name',
        license_plate: 'License Plate',
        brand: 'Brand',
        model: 'Model',
        status: 'Status',
        transmission: 'Transmission',
        fuel_type: 'Fuel Type',
        seats: 'Seats',
        id: 'ID',
        reason: 'Reason',
        date_and_time: 'Date & Time',
        detail: 'Detail',
        event: 'Event',
        user: 'User',
        description: 'Description',
        vehicle_details: 'Vehicle Details',
        vehicle_images: 'Vehicle Images',
        vehicles_brands: 'Vehicle\'s Brands',
        status_change: 'Status Change',
        vehicle: 'Vehicle',
        vehicles: 'Vehicles',
        add_brand: 'Add Brand',
        edit_brand: 'Edit Brand',
        issue_history: 'Issue History',
        issue: 'Issue',
        issues: 'Issues',
        audits: 'Audits',
        upload: 'Upload',
        add_vehicle: 'Add Vehicle',
        add_vehicle_brand: 'Add Vehicle Brand',
        search_vehicles: 'Search vehicles',
        delete_vehicle: 'Delete Vehicle',
        delete_brand: 'Delete Brand',
        prompt_delete: 'Are you sure you want to delete this vehicle?',
        prompt_delete_brand: 'Are you sure you want to delete this brand?',
        error_delete: 'Failed to delete vehicle',
        error_retrieve: 'Failed to retrieve vehicles',
        error_create: 'Failed to create vehicle',
        error_update: 'Failed to update vehicle',
        success_delete: 'Vehicle has been deleted',
        success_created: 'Vehicle has been created',
        success_updated: 'Vehicle has been updated',
        status_change_description: 'Please choose from below why the status changed',
        error_retrieve_issue_history: 'Failed to retrieve issue history',
        error_retrieve_issues: 'Failed to retrieve issues list',

    },
    profile: {
        profile: 'profile',
        user_account: 'User Account',
        personal_information: 'Personal Information',
        address_information: 'Address Information',
        change_password: 'Change Password',
        success_updated: 'Your Account has been updated',
        error_update: 'Failed to update profile'
    },
    users: {
        users: 'users',
        add_user: 'Add User',
        edit_user: 'Edit User',
        search_users: 'Search users',
        name: 'Name',
        company: 'Company',
        mobile_number: 'Mobile Number',
        phone_mobile_number: 'Phone / Mobile Number',
        address: 'Address',
        email: 'Email',
        email_address: 'Email Address',
        company_name: 'Company Name',
        vat_number: 'VAT Number',
        city: 'City',
        post_code: 'Post Code',
        password: 'Password',
        confirm_password: 'Confirm password',
        role: 'Role',
        roles: 'Roles',
        actions: 'Actions',
        prompt_delete: 'Are you sure you want to delete this user?',
        prompt_delete_subtext: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed massa metus, ornare nec dui aliquam, dapibus dictum leo.',
        success_created: 'User has been created',
        success_updated: 'User has been updated',
        error_create: 'Failed to create user',
        error_update: 'Failed to update user',
        error_delete: 'Failed to delete user',
        error_retrieve: 'Failed to retrieve users',
        user_details: 'User Details',
        assigned_calculators: 'Assigned Calculators',
        assigned_blinds: 'Assigned Blinds',
        blinds: 'Blinds',
        update_user: 'Update User',
        delete_user: 'Delete User',
        add_blind: 'Add Blind',
        blind: 'Blind',
        error_assign_blind: 'Failed to assign blind',
        error_retrieve_blinds: 'Failed to retrieve blinds',
        remove_blind: 'Remove Blind',
        prompt_remove_blind: 'Are you sure you want to detach this blind?',
        error_detach_blind: 'Failed to detach blind',
        obo: 'O.B.O',
    },

    reports: {
        reports: 'Reports',
        select_a_report_type: 'Select a report type',
        type_1: 'Type 1',
        type_2: 'Type 2',
        type_3: 'Type 3',
        export: 'Export',
        generate_report: 'Generate Report',
        car_bookings_report: 'Car Bookings Report',
        license_plate: 'License Plate',
        time_frame: 'Time Frame',
        choose_a_license_plate: 'Please choose a License Plate',
        choose_a_pickup_date: 'Please choose a Pick-up Date',
        choose_a_dropoff_date: 'Please choose a Drop-off Date',
    },

    roles: {
        roles: 'roles',
        permissions: 'Permissions',
        permission: 'Permission',
        actions: 'Actions',
        name: 'Name',
        add_role: 'Add Role',
        edit_role: 'Edit Role',
        search_roles: 'Search roles',
        prompt_delete: 'Are you sure you want to delete this role?',
        prompt_delete_subtext: '',
        error_retrieve: 'Failed to retrieve roles',
        error_create: 'Failed to create role',
        error_update: 'Failed to update role',
        error_delete: 'Failed to delete role',
        success_created: 'role has been created',
        success_updated: 'role has been updated',
        error_retrieve_fields: 'Failed to retrieve fields',
        role_details: 'Role Details',
        delete_role: 'Delete Role',
    },
    permissions: {
        permission: 'permission',
        permissions: 'permissions',
        error_retrieve: 'Failed to retrieve permissions',
        error_save: 'Failed to save permission',
        details_saved: 'permission details have been saved',
        saved: 'permission has been saved',
    },
    prompts: {
        are_you_sure: 'are you sure?',
        do_you_want_to_delete_this_x: 'do you want to delete this {x}?',
    },
    validation: {
        x_is_required: '{x} is required',
        x_are_required: '{x} are required',
        please_enter_x: 'please enter {x}',
        please_enter_longer_x: 'please enter a longer {x}',
        please_enter_shorter_x: 'please enter a shorter {x}',
        please_enter_valid_x: 'please enter a valid {x}',
        please_choose_x: 'please choose {x}',
        please_enter_number: 'please enter a number',
        please_enter_number_between_x: 'please enter a number between {x} and {y}',
        please_enter_url: 'please enter a URL',
    },
    auth: {
        unauthorized: 'Unauthorized',
        unauthenticated: 'Unauthenticated',
        forgot_password_q: 'Forgot password?',
        forgot_password: 'Forgot password',
        forgot_password_text: 'Enter your email and we\'ll send you a link to reset your password.',
        password_has_been_updated: 'Your password has been updated!',
        email: 'Email',
        password: 'Password',
        password_is_required: 'Password is required',
        email_is_required: 'Email is required',
        reset_password: 'Reset Password',
        enter_valid_email: 'Enter a valid email',
        email_sent: 'Email successfully sent',
        return_to_login: 'Return to Login',
        enter_your_new_password: 'Enter your new password',
        retype_password: 'Re-type password',
        failed_login: 'Login Failed',
        failed_reset: 'Reset Failed',
        identity_verified: 'Your identity has been verified!',
        set_new_password: 'Set your new password!',
    },
    localities: {
        select_locality: 'Select locality',
        error_retrieve: 'Failed to retrieve localities',
    },
    cancel: 'Cancel',
    delete: 'Delete',
    remove: 'Remove',
    start: 'Start',
    stop: 'Stop',
    finish: 'Finish',
    add: 'Add',
    update: 'Update',
    save: 'Save',
    print: 'Print',
    send: 'Send',
    search: 'Search',
    select: 'Select',
    page_not_found: 'Page not found',
    x_per_page: '{x} per page',
    error: 'Error',
    yes: 'Yes',
    no: 'No',
    accepted: 'Accepted',
    not_accepted: 'Not Accepted',
    warning: 'Warning',
    actions: 'Actions',
    back: 'Back',
    submit: 'Submit',
    close: 'Close',
    upload: 'Upload',
}
