<template>
    <div class="login-page-container">
        <div class="content">
            <img src="../assets/logo.svg" class="logo " alt="logo"/>

            <Form v-if="screen === 'login'" @submit="login" :disabled="is_processing" class="form-container login-form">

                <FormInputText v-model="$v.credentials.email.$model" autocomplete="on" :placeholder="$t('users.email')"
                               :disabled="is_processing" :has-error="$v.credentials.email.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.credentials.email.required">
                            {{ $t('auth.email_is_required') }}
                        </p>
                        <p v-else-if="!$v.credentials.email.email">
                            {{ $t('auth.enter_valid_email') }}
                        </p>
                    </template>
                </FormInputText>

                <FormInputPassword v-model="$v.credentials.password.$model" identifier="password"
                                   :placeholder="$t('auth.password')" :disabled="is_processing" type="password"
                                   :has-error="$v.credentials.password.$error" autocomplete="on">
                    <template v-slot:errors>
                        <p v-if="!$v.credentials.password.required">
                            {{ $t('validation.x_is_required', {x: $t('auth.password')}) }}
                        </p>
                        <p v-else-if="!$v.credentials.password.minLength">
                            {{ $t('validation.password_must_be_atleast_x_characters', {x: 8}) }}
                        </p>
                    </template>
                </FormInputPassword>

                <div class="submit-row">
                    <Button :disabled="is_processing" className="--primary" :class="{'spinner-black': is_processing}"
                            type="submit">
                        <span>{{ $t('nav.login') }}</span>
                    </Button>

                    <a @click="screen = 'forgot'" class="forgot">
                        {{ $t('auth.forgot_password_q') }}
                    </a>
                </div>
            </Form>

            <div v-else-if="screen === 'forgot'" class="forgot-wrapper">
                <button class="back" @click="screen = 'login'">
                    <font-awesome-icon :icon="['fal','long-arrow-left']"/>
                </button>
                <Form @submit="forgot" :disabled="is_processing" class="form-container forgot-form">

                    <p>{{ $t('auth.forgot_password_text') }}</p>

                    <FormInputText v-model="$v.credentials.email.$model" autocomplete="off" :has-error="$v.credentials.email.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.credentials.email.required">
                                {{ $t('auth.email_is_required') }}
                            </p>
                            <p v-else-if="!$v.credentials.email.email">
                                {{ $t('auth.enter_valid_email') }}
                            </p>
                        </template>
                    </FormInputText>

                    <div class="submit-row">
                        <Button :disabled="is_processing" className="--primary mt-4"
                                :class="{'spinner-black': is_processing}" type="submit">
                            {{ $t('auth.reset_password') }}
                        </Button>
                    </div>
                </Form>
            </div>
            <Form v-else-if="screen === 'reset'" class="reset-form">
                <p>{{ $t('auth.email_sent') }}</p>

                <font-awesome-icon :icon="['fal','check-circle']"/>
                <div class="submit-row">
                    <Button className="--primary" :onclick="() => this.screen = 'login'">
                        {{ $t('nav.login') }}
                    </Button>
                </div>
            </Form>
        </div>
        <div class="engineered-by-arkafort">
            <p>Engineered by</p>
            <img src="../assets/logo-white.svg" alt="Arkafort"/>
        </div>
    </div>
</template>

<script>
import {required, email} from 'vuelidate/lib/validators'
import Button from "../components/Button";
import Form from "../components/form/Form";
import FormInputText from "@/components/form/FormInputText";
import FormInputPassword from "@/components/form/FormInputPassword.vue";

export default {
    name: "login-page",
    components: {FormInputPassword, FormInputText, Form, Button},
    data() {
        return {
            credentials: {
                email: '',
                password: '',
            },
            show_password: false,
            is_processing: false,
            screen: 'login',
        }
    },
    validations: {
        credentials: {
            email: {
                required,
                email,
            },
            password: {
                required,
            }
        }
    },
    methods: {
        login() {
            this.$v.credentials.$touch();
            if (this.$v.credentials.$anyError || this.is_processing)
                return;

            this.is_processing = true;
            this.$axios.post('auth/login', {
                email: this.$v.credentials.email.$model,
                password: this.$v.credentials.password.$model,
            }).then(async ({data}) => {
                this.$store.commit("SET_TOKEN", data.data.token);
                await this.$store.dispatch('getUser');

                this.is_processing = false;
                this.$router.push('/calendar');
            }).catch(e => {
                this.is_processing = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('auth.failed_login')),
                    type: 'error',
                });
            });
        },
        forgot() {
            this.$v.credentials.email.$touch();
            if (this.$v.credentials.email.$anyError || this.is_processing)
                return;

            this.is_processing = true;
            this.$axios.post('auth/password/reset/request', {
                email: this.$v.credentials.email.$model,
            }).then(async ({data}) => {
                this.screen = 'reset';

                this.is_processing = false;
            }).catch(e => {
                this.is_processing = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('auth.failed_reset')),
                    type: 'error',
                });
            });
        }
    },
    head() {
        return {
            title: {
                inner: this.$t('nav.login')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
.login-page-container {
    @apply bg-cover bg-center bg-no-repeat bg-primary-over min-h-screen w-full flex flex-col justify-center items-center;

    &.darken {
        position: relative;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: block;
            background-color: rgba(0, 0, 0, 0.2);
        }
    }

    .content {
        @apply px-10 py-8 rounded-lg flex flex-col max-w-full bg-white;

        width: 90vw;
        //background-color: rgba(0, 0, 0, 0.65);
        z-index: 1;

        @screen sm {
            width: 478px;
        }

        .logo {
            @apply w-auto mt-8 mb-12 object-contain h-8;
        }

        .form-container {
            @apply bg-white;

            & > .input-group {
                margin-bottom: 1rem !important;

                input {
                    color: theme('colors.black') !important;
                    height: 50px;
                }

                .password-field {
                    height: 50px;

                    & > button {
                        @apply mt-1;
                    }
                }
            }

            .submit-row {
                @apply flex flex-row justify-between items-center;
                margin-top: 0rem !important;

                a.forgot {
                    @apply text-black text-sm font-medium no-underline cursor-pointer;
                }
            }
        }

        .login-form {
            .errors {
                @apply mt-3 mb-1;

                p {
                    @apply text-negative italic text-sm py-1 ml-4;
                }
            }

            button[type="submit"] {
                @apply self-start;
            }

            .submit-row {
                @apply flex flex-row flex-wrap mt-3;

                button[type=button] {
                    @apply ml-2;
                }
            }
        }

        .forgot-wrapper {
            @apply relative;

            button.back {
                @apply absolute;
                top: -8rem;
                left: -0.5rem;

                svg {
                    @apply text-black text-3xl;
                }

                &:active, &:focus {
                    @apply outline-none;
                }
            }

            .forgot-form {
                @apply flex flex-col items-center;

                & > p {
                    @apply text-black font-normal leading-6 text-sm text-center -mt-3 mb-2;
                    max-width: 16rem;
                }
            }
        }


        .reset-form {
            @apply flex flex-col items-center mb-7;

            & > p {
                @apply text-black font-normal text-sm text-center max-w-92 mt-3;
            }

            svg {
                @apply text-secondary my-8;
                font-size: 5.65rem;
            }

            .submit-row {
                @apply flex flex-row justify-between items-center w-full;

                button {
                    @apply mx-auto cursor-pointer;
                }
            }
        }
    }

    .engineered-by-arkafort {
        @apply flex flex-row absolute items-center;
        z-index: 9999;
        top: 95%;

        & > p {
            @apply text-sm text-white;
        }

        & > img {
            @apply ml-4 h-4;
        }
    }
}
</style>
